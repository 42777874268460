// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-extra-blank-js": () => import("./../../../src/pages/extra/blank.js" /* webpackChunkName: "component---src-pages-extra-blank-js" */),
  "component---src-pages-extra-clicker-js": () => import("./../../../src/pages/extra/clicker.js" /* webpackChunkName: "component---src-pages-extra-clicker-js" */),
  "component---src-pages-extra-index-js": () => import("./../../../src/pages/extra/index.js" /* webpackChunkName: "component---src-pages-extra-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platform-jump-index-js": () => import("./../../../src/pages/platform-jump/index.js" /* webpackChunkName: "component---src-pages-platform-jump-index-js" */),
  "component---src-pages-sorry-js": () => import("./../../../src/pages/sorry.js" /* webpackChunkName: "component---src-pages-sorry-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

